//#region IMPORTS
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as otpJsonData from '../../../../../assets/otp/otp.json';
import {  Router } from '@angular/router';
import { MemberPortalLoginUserResponse, UserVerificationResponse } from '@core/models/universal';
import { AuthService } from '@core/services/auth.service';
import { UiService } from '@core/services/ui.service';
import { Parameter } from '@core/util/model/api-service/parameter.model';
import { AppResponse } from '@core/util/model/response-models/app-response';
import { GlobalMasterService } from '@core/util/services/global-master.service';
import { ValidationService } from '@core/util/services/validation.service';
import { Location } from '@angular/common';
import { forkJoin, Observable } from 'rxjs';
import { GlobalConfigsService } from '@core/util/services/global-configs.service';
import { ServerInBuiltError } from '@core/util/model/api-service/ServerInBuiltError.model';
//#endregion

@Component({
  selector: 'app-member-login-confirmation',
  templateUrl: './member-login-confirmation.component.html',
  styleUrls: ['./member-login-confirmation.component.scss']
})
export class MemberLoginConfirmationComponent implements OnInit {

  //#region EXTERNAL_COMMUNICATORS
  //#endregion

  //#region VARIABLES
  /**
  * Whether the main content is displayed or a spinner is displayed
  * @defaultValue `false`
  */
  public isMainPageLoading = false;

  /**
  * Whether the Login button is displayed or a spinner is displayed
  * @defaultValue `false`
  */
  public isLoginLoading = false;

  /**
  * Whether the Resend button is displayed or a spinner is displayed
  * @defaultValue `false` 
  */
  public isResendLoading = false;

  /**
  * Whether the OTP wait Timer is displayed;
  * @defaultValue `false`  
  */

  public isOtpWaitTimerDisplayed = false;
  public sentOTPCount = 0
  public loginOTPForm: FormGroup;
  public verifiedData: MemberPortalLoginUserResponse;
  public otpVerified = false;
  public language = 'ENG'
  public verifiedOtpValue;
  public otpGenratedInterval: number = otpJsonData.LOGIN_OTP_INTERVAL;
  //#endregion

  constructor(
    private router: Router,
    private uiService: UiService,
    private formBuilder: FormBuilder,
    private validationService: ValidationService,
    private globalMasterService: GlobalMasterService,
    private configService: GlobalConfigsService,
    public dialog: MatDialog,
    private authService: AuthService,
    private _location: Location,
  ) { }

  ngOnInit(): void {
    this.initloginOTPForm();
    this.callVerificationApis()
  }

  get memberLoginInvitationFormControls() {
    return this.loginOTPForm.controls
  }


  public backClick() {
    this._location.back();
  }

  private initloginOTPForm() {
    this.loginOTPForm = this.formBuilder.group({
      loginOtp: [null, { validators: this.validationService.getValidations('login_otp') }]
    })
  }

  /**
   * Calls the Apis at Screen initialization to verify the user.
   * @remarks
   * The 1 st api gets the hash value which is set in localstorage and will be appended to the header for all api calls.
   * The 2 nd api is called to verify the user and send the initial otp value to mobile or email
   */
  private async callVerificationApis() {

    const mainParamList = new Array<Parameter>();

    this.isMainPageLoading = true;

    // Calls pre verification Api to get header hash
    this.globalMasterService.executeApiRequest<AppResponse<UserVerificationResponse>>(
      'MemberPortalUserInvitation_userVerify_1',
      mainParamList)
      .subscribe(async (res: AppResponse<UserVerificationResponse>) => {
        if (!res.error) {
          // setting header value
          this.configService.setHeaderHashValue(res.data?.header_value);

          const parameterList = new Array<Parameter>();

          //get Username from authService
          const username = await this.authService.getUsername()
          const request = {
            username: username.toUpperCase()
          }

          parameterList.push({ name: 'request', value: request })

          //showing the Spinner
          this.isMainPageLoading = true;
          // Calls verification Api to get user data and verify other conditions
          this.globalMasterService.executeApiRequest<AppResponse<MemberPortalLoginUserResponse>>(
            'MemberPortalUserInvitation_loginVerify_1',
            parameterList)
            .subscribe((res: AppResponse<MemberPortalLoginUserResponse>) => {
              if (!res.error) {
                this.verifiedData = res?.data
                //hiding the spinner
                this.isMainPageLoading = false;
                this.setAndStartOTPTimer();
              } else {
                this.verifiedData = null;
                this.isMainPageLoading = false;
                this.uiService.showErrorToastr('Error', res.error.detail);
              }
            });
        } else {
          this.verifiedData = null;
          this.isMainPageLoading = false;
          this.uiService.showErrorToastr('Error', res.error.detail);
        }
      });


  }
  /**
   * Calls the Apis to Verify the Otp Value Entered.
   */
  public callVerifYOTP() {
    const parameterList = new Array<Parameter>();
    const request = {
      contact_value: null,
      otp_type: 8,
      otp_value: this.loginOTPForm.value.loginOtp
    }

    parameterList.push({ name: 'request', value: request })
    this.isLoginLoading = true;
    this.globalMasterService.executeApiRequest<AppResponse<MemberPortalLoginUserResponse>>(
      'MemberPortalOtpManage_verifyOtp_1',
      parameterList)
      .subscribe((res: AppResponse<MemberPortalLoginUserResponse>) => {
        if (!res.error) {

          this.isLoginLoading = false;
          this.uiService.showSuccessToastr('Success', 'OTP Verified');
        } else {
          this.isLoginLoading = false;
          this.uiService.showErrorToastr('Error', res.error.detail);
        }
      });
  }

  public resendOtp() {
    this.otpGenratedInterval = otpJsonData.LOGIN_OTP_INTERVAL;
    this.sentOTPCount += 1
    //if mobile and Email Both available
    if (this.verifiedData?.mobile_otp_generate?.contact_value && this.verifiedData?.email_otp_generate?.contact_value) {

      const mobileObs =  this.getResendOTPObservable(4, 'SMS', this.verifiedData.mobile_otp_generate.contact_value, false, this.verifiedData.mobile_otp_generate.member_id)

      const emailObs = this.getResendOTPObservable(4, 'EM', this.verifiedData.email_otp_generate.contact_value, false, this.verifiedData.email_otp_generate.member_id)

      this.isResendLoading = true;
      forkJoin([mobileObs, emailObs]).subscribe(
        (response) => {
          this.isResendLoading = false;
          this.setAndStartOTPTimer();
          this.uiService.showSuccessToastr('Success', 'OTP Sent to Mobile & Email');
        })
    }
    //if only mobile is available
    else if (this.verifiedData?.mobile_otp_generate?.contact_value) {
      this.isResendLoading = true;
      this.getResendOTPObservable(4, 'SMS', this.verifiedData.mobile_otp_generate.contact_value, false, this.verifiedData.mobile_otp_generate.member_id)
        .subscribe((res: AppResponse<MemberPortalLoginUserResponse>) => {
          if (!res.error) {
            this.isResendLoading = false;
            this.setAndStartOTPTimer();
            this.uiService.showSuccessToastr('Success', 'OTP Sent to Mobile');
          } else {
            this.isResendLoading = false;
            this.uiService.showErrorToastr('Error', res.error.detail);
          }
        });
    }
   //if only Email is available
    else if (this.verifiedData?.email_otp_generate?.contact_value) {
      this.isResendLoading = true;
      this.getResendOTPObservable(4, 'EM', this.verifiedData.email_otp_generate.contact_value, false, this.verifiedData.email_otp_generate.member_id)
        .subscribe((res: AppResponse<MemberPortalLoginUserResponse>) => {
          if (!res.error) {
            this.isResendLoading = false;
            this.setAndStartOTPTimer();
            this.uiService.showSuccessToastr('Success', 'OTP Sent to Email Address');
          } else {
            this.isResendLoading = false;
            this.uiService.showErrorToastr('Error', res.error.detail);
          }
        });
    }
  }


  public loginClick() {
    const parameterList = new Array<Parameter>();

    const request = {
      language_code: this.language,
      otp_value: this.loginOTPForm.value.loginOtp,
      user_id: this.verifiedData.id,
    }

    parameterList.push({ name: 'request', value: request })
    this.isLoginLoading = true;
    this.globalMasterService.executeApiRequest<AppResponse<MemberPortalLoginUserResponse>>(
      'MemberPortalUserInvitation_loginUser_1',
      parameterList)
      .subscribe((res: AppResponse<MemberPortalLoginUserResponse>) => {
        console.log(res)
        if (!res.error) {
          this.isLoginLoading = false;
          this.router.navigate(['etfb-member-portal'])
          this.uiService.showSuccessToastr('Success', 'Login Successful');
        } else {
          this.isLoginLoading = false;
          this.uiService.showErrorToastr('Error', res.error.detail);
        }
      });
  }

  /**
  * Starting and setting otp Resend Timer.
  *
  * @param timerStartTime - The Start time, defaults to value from config
  *
  */
  private setAndStartOTPTimer(timerStartTime = otpJsonData.LOGIN_OTP_INTERVAL) {
    this.otpGenratedInterval = timerStartTime;
    this.isOtpWaitTimerDisplayed = true;
    const interval = setInterval(() => {
      if (this.otpGenratedInterval === 0) {
        clearInterval(interval);
      }
      this.otpGenratedInterval = this.otpGenratedInterval - 1;
      if (this.otpGenratedInterval <= 0) {
        this.isOtpWaitTimerDisplayed = false;
      }
    }, 1000);
  }

  /**
   * Resending the OTP to mobile or Email.
   *
   * @param otpType - OTP type number (for backend)
   * @param otpTypeCode - OTP type Code ('EM' || 'MB')
   * @param contactTypeCode - OTP type number (for backend)
   * @param contactValue - Contact Value 
   * @returns The observable for api call
   */
      private getResendOTPObservable(otpType:number,contactTypeCode:string,contactValue:string,adminUser=false,memberId:string |number) : Observable<AppResponse<any> | ServerInBuiltError>  {
        const parameterList = new Array<Parameter>();
        const request ={
          contact_value:  contactValue,
          otp_type: otpType,
          message_contact_type_code: contactTypeCode,
          admin_user: adminUser,	
          member_id: memberId
       }
    
       parameterList.push({name:'request',value:request})
       this.isResendLoading =true;
       return this.globalMasterService.executeApiRequest<AppResponse<MemberPortalLoginUserResponse>>(
        'MemberPortalOtpManage_create_1',
        parameterList)
    }

}
