import {Injectable, ChangeDetectorRef, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import { ConfirmationMessageComponent } from '@core/components/confirmation-message/confirmation-message.component';
import { Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationMessageService {
  dialogRef: MatDialogRef<ConfirmationMessageComponent>;
  constructor(
    private dialog: MatDialog,
  ) { }

  public openConfirmationDialog() {
    this.dialogRef = this.dialog.open(ConfirmationMessageComponent, {
      width: '30rem',
      // data: null,
      panelClass: 'custom-modalbox'
    });
  }

  public openConfirmationDialogWithMessage(message: string) {
    this.dialogRef = this.dialog.open(ConfirmationMessageComponent, {
      width: '30rem',
      data: message,
      panelClass: 'custom-modalbox'
    });
  }

  public openConfirmationCancelDialog() {
    this.dialogRef = this.dialog.open(ConfirmationMessageComponent, {
      width: '30rem',
      data: 'cancel',
      panelClass: 'custom-modalbox'
    });
  }

  getConfirmMessage(): Observable<any> {
    return this.dialogRef.afterClosed().pipe(take(1), map(res => {
      return res;
    }
    ));
  }
}
