import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class UiService {
  private title = new BehaviorSubject<string>('App title');
  private title$ = this.title.asObservable();

  constructor(private snackbar: MatSnackBar, private toastr: ToastrService, private translateService: TranslateService) {}

  // Setting Page Titles
  setTitle(title: string) {
    this.title.next(title);
  }

  // Getting Page Titles
  getTitle(): Observable<string> {
    return this.title$;
  }

  // Snack Bar
  showSnackBar(message, action, displayDuration) {
    this.snackbar.open(message, action, {
      duration: displayDuration,
      verticalPosition: 'top',
      horizontalPosition: 'right',
    });
  }

  showSuccessToastr(header, message) {
    this.toastr.success(this.translateService.instant(message), this.translateService.instant(header), {
      disableTimeOut: true,
      closeButton: true,
      extendedTimeOut: 0,
      timeOut: 0
    });
  }

  showErrorToastr(header, message) {
    this.toastr.error(this.translateService.instant(message), this.translateService.instant(header), {
      disableTimeOut: true,
      closeButton: true,
      extendedTimeOut: 0,
      timeOut: 0
    });
  }

  showWarningToastr(header, message) {
    this.toastr.warning(this.translateService.instant(message), this.translateService.instant(header), {
      disableTimeOut: true,
      closeButton: true,
      extendedTimeOut: 0,
      timeOut: 3000
    });
  }

  // Scroll To Top
  scrollTop() {
    window.scrollTo(0, 0);
  }
}
