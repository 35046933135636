import { HttpClient } from '@angular/common/http';
import { of, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { AuthService } from '@core/services/auth.service';
import { UploadingDocument } from '@core/models/universal/first-phase-global-components/documents/uploading-document.model';
import { AppResponse } from '@core/util/model/response-models/app-response';
import { DocumentDetailResponse } from '@core/models/universal/first-phase-global-components/documents/document-detail-response.model';
import { NoteUpdateRequest } from '@core/models/universal/first-phase-global-components/documents/note-update-request.model';
import { UiService } from '@core/services/ui.service';

@Injectable({
  providedIn: 'root'
})
export class MstDocumentService {

  constructor(
    private httpClient: HttpClient,
    private authService: AuthService,
    private sanitizer: DomSanitizer,
    private uiService: UiService,
  ) { }

  DATA_URL = environment.BASE_DATA_URL;

  public saveDocument(uploadingDocument: UploadingDocument): Observable<AppResponse<DocumentDetailResponse>> {
    const formData = new FormData();
    formData.append('documentTypeId', uploadingDocument.documentTypeId.toString());
    formData.append('name', uploadingDocument.name);
    formData.append('note', uploadingDocument.note);

    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < uploadingDocument.documentList.length; i++) {
      formData.append('documentList', uploadingDocument.documentList[i], uploadingDocument.documentList[i].name);
    }

    const url = this.DATA_URL + environment.COMMON_URI.DOCUMENTS.UPLOAD_DOCUMENT;
    return this.httpClient.post<AppResponse<DocumentDetailResponse>>(url, formData)
      .pipe(map(response => response));
  }

  public async downloadDocument(dmsRef: string): Promise<void> {
    const url = this.DATA_URL + environment.COMMON_URI.DOCUMENTS.DOWNLOAD_DOCUMENT;
    const token = await this.authService.getToken();
    const parameterizedUrl = `${url}/${dmsRef}?access_token=${token}`;
    window.open(parameterizedUrl);
  }

  public viewDocumentOnNewTab(dmsRef: string, defaultPath?: string) {

    if (!dmsRef) {
      if (!defaultPath) {
        return of('/assets/images/no_image.PNG');
      }
      return of(defaultPath);
    }


    const url = this.DATA_URL + environment.COMMON_URI.DOCUMENTS.DOCUMENT_VIEW + '/' + dmsRef;
    return this.httpClient.get<AppResponse<any>>(url).pipe(map(response => {

      if (!response.data) {
        return
      }
      var byteCharacters = atob(response.data);
      var byteNumbers = new Array(byteCharacters.length);
      for (var i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      var file = new Blob([byteArray], { type: 'application/pdf;base64' });
      var fileURL = URL.createObjectURL(file);
      window.open(fileURL);
      //         let pdfWindow = window.open("")
      // pdfWindow.document.write(
      //     "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
      //     encodeURI(response.data) + "'></iframe>"
      // )
      // return this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + response.data);
    })).subscribe();
  }

  public getCompanyLogoImagePath(dmsRef: string) {
    const defaultLogo = '/assets/images/user/company-logo.png';    // todo
    return this.viewDocument(dmsRef, defaultLogo);
  }

  public getPersonProfileImagePath(dmsRef: string) {
    const defaultLogo = '/assets/images/user/default-user.png';    // todo
    return this.viewDocument(dmsRef, defaultLogo);
  }


  public getSignatureImagePath(dmsRef: string) {
    const defaultLogo = '/assets/images/no_image.PNG';    // todo
    return this.viewDocument(dmsRef, defaultLogo);
  }

  private viewDocument(dmsRef: string, defaultPath: string) {
    if (!dmsRef) {
      if (!defaultPath) {
        return of('/assets/images/no_image.PNG');
      }
      return of(defaultPath);
    }
    // if (!dmsRef) { return new Observable<string>(); }

    const url = this.DATA_URL + environment.COMMON_URI.DOCUMENTS.DOCUMENT_VIEW + '/' + dmsRef;
    return this.httpClient.get<AppResponse<any>>(url).pipe(map(response => {
      if (!response.data) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(defaultPath);
      }
      return this.sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + response.data);
    }));
  }


  downloadAms(request) {
    const header = { Accept: "application/octet-stream" };
    const appRequest: AppResponse<any> = { data: request };
    this.httpClient.post(
      // environment.BASE_DATA_URL + environment.BUSSINESS_URIS.EMPLOYER_RELATIONS.REPORT_DOWNLOAD,
      environment.BASE_DATA_URL2 + environment.BUSSINESS_URIS.EMPLOYER_RELATIONS.REPORT_DOWNLOAD,
      appRequest,
      { responseType: 'blob' }
    )
      .subscribe(response => {
        if (!response) {
          return;
        }
        const file = new Blob([response], { type: 'application/octet-stream' });
        const fileURL = URL.createObjectURL(file);
        const anchor = document.createElement('a');
        anchor.download = "ALOC_annual_member_statement.pdf";
        anchor.href = fileURL;
        anchor.click();
      }), () => {
        this.uiService.showErrorToastr('Error', 'Download Failed');
      }
  }

  
}

