import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from '@block/pages/not-found/not-found.component';
import { AuthGuard } from '@core/services/auth.guard';
import { MemberLoginInvitationComponent } from '@block/pages/landing/member-login-invitation/member-login-invitation.component';
import { MemberLoginConfirmationComponent } from '@block/pages/landing/member-login-confirmation/member-login-confirmation.component';
import { CreatePortalMemberLandingComponent } from '@block/pages/landing/create-portal-member-landing/create-portal-member-landing.component';
import { ResetPasswordComponent } from '@block/pages/landing/reset-password/reset-password.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'etfb-member-portal',
    pathMatch: 'full'
  },
  {
    path: 'login-invitation',
    component: MemberLoginInvitationComponent,
  },
  {
    path: 'member-login',
    component: MemberLoginConfirmationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'create-member',
    component: CreatePortalMemberLandingComponent,
  },
  {
    path: 'forgot-password',
    component: ResetPasswordComponent,
  },
  {
    path: 'not-found',
    component: NotFoundComponent
  },
  { path: 'etfb-member-portal', loadChildren: () => import('./etfb-member-portal/etfb-member-portal.module').then(m => m.EtfbMemberPortalModule) },
  {
    path: '**',
    redirectTo: '/not-found',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top', 
  })
],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule { }
