import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/internal/operators/catchError';
import { ApiRequest } from '../model/api-service/apiRequest.model';
import { RequestType } from '../model/api-service/apiRequest.model';
import { Parameter } from '../model/api-service/parameter.model';
import { AppResponse } from '../model/response-models/app-response';
import { ServerInBuiltError } from '../model/api-service/ServerInBuiltError.model';
import { AppRequest } from '../model/request-models/app-request';

import baseUrls from 'src/assets/urls/base-urls.json';
import { GlobalConfigsService } from './global-configs.service';
// import urlInfoMeta from 'src/assets/api-service-configs/meta.json';
// import urlInfoBu from 'src/assets/api-service-configs/bu.json';
// import urlInfoReport from 'src/assets/api-service-configs/report.json';

@Injectable({ providedIn: 'root' })

export class GlobalMasterService {

  private defaultVersion = 'v1';
  private queryCount = 0;

  private readonly tempUrlInfoMeta;
  private readonly tempUrlInfoReport;
  private readonly tempUrlInfoBu;

  constructor(private httpClient: HttpClient ,private globalConfigService:GlobalConfigsService) {
    let mode: 'dev' | 'qa' | 'uat' | 'staging' | 'preProd' | 'prod';
    mode = environment.CURRENT_MODE as typeof mode;

    switch (mode) {
      case 'uat':
        this.tempUrlInfoMeta = require('src/assets/api-service-configs/uat/meta.json');
        this.tempUrlInfoBu = require('src/assets/api-service-configs/uat/bu.json');
        break;
      case 'staging':
        this.tempUrlInfoMeta = require('src/assets/api-service-configs/staging/meta.json');
        this.tempUrlInfoBu = require('src/assets/api-service-configs/staging/bu.json');
        break;
      case 'prod':
        this.tempUrlInfoMeta = require('src/assets/api-service-configs/prod/meta.json');
        this.tempUrlInfoBu = require('src/assets/api-service-configs/prod/bu.json');
        break;
      default:
        this.tempUrlInfoMeta = require('src/assets/api-service-configs/meta.json');
        this.tempUrlInfoBu = require('src/assets/api-service-configs/bu.json');
        break;
    }
  }

  public executeApiRequest<T>(urlCode: string, parameterList: Array<Parameter>, isMeta?: boolean, version?: string): Observable<AppResponse<any> | ServerInBuiltError> {

    let name: string;
    let value: any;
    let parameter: Parameter;
    let bodyData: {};

    version = version ?? this.defaultVersion;

    const apiRequestInfo = isMeta ? this.tempUrlInfoMeta : this.tempUrlInfoBu;

    // tslint:disable-next-line: no-string-literal
    const urlDetails = apiRequestInfo['urlInfo'].find(urlInfo => urlInfo.operationId === urlCode && urlInfo.version === version) as ApiRequest;
    let url = urlDetails.path;
    const httpRequest = urlDetails.httpRequest;

    if (!this.propertiesValidation(urlDetails.requestTypeList, parameterList)) {
      window.alert(`Parameters you entered are not valid!, Try again with valid parameters`);
      return null;
    }

    let formData = new FormData();
    urlDetails.requestTypeList.forEach(requestType => {

      parameter = parameterList
        .find(parameter => parameter.name === requestType.name) as Parameter;

      if (!parameter && requestType.required) {
        window.alert(`Parameter "${requestType.name}" is required!, Try again with "${requestType.name}"`);
      } else if (!parameter && !requestType.required) {
        // continue;
      } else {
        name = parameter.name;
        value = parameter.value;

        let expression: 'path' | 'body' | 'query' | 'formData';
        expression = requestType.in;

        switch (expression) {
          case 'body': {
            bodyData = value;
            break;
          }
          case 'path': {
            url = url.replace(`{${name}}`, value);
            break;
          }
          case 'query': {
            // setting lang globally
            if(name ==='lang'){
              url = this.setQuery('lang', this.globalConfigService.getcurrentLanguage(), url);
            }else{
              url = this.setQuery(name, value, url);
            }
         
            break;
          }
          case 'formData': {
            formData = this.setFormData(requestType, parameter, formData);
            break;
          }
          default: {
            break;
          }
        }
      }

      parameter = undefined;
    });
    this.queryCount = 0;

    const getBaseUrl = this.getBaseUrl(isMeta);

    url = `${getBaseUrl}/${version}${url}`;

    const appRequest: AppRequest<any> = { data: bodyData };

    var header: HttpHeaders = new HttpHeaders();

    return this.httpClient
      .request<AppResponse<T>>(httpRequest, url, { body: bodyData === undefined ? formData : appRequest, responseType: 'json', headers: header })
      .pipe(
        catchError((err: HttpErrorResponse) => {
          console.log(err,'Error')
          if (err.error instanceof ErrorEvent) {
            return of<AppResponse<any>>({
              error: {
                title: 'Network error!',
                detail: err.message,
                source: err.statusText,
                status: err.status.toString()
              },
              meta: {
                code: err.status,
                message: err.message
              }
            });
          }
          return of<HttpErrorResponse>(err);
        }));
  }

  private setQuery(name: string, value: any, url: string): string {

    url = (this.queryCount === 0) ? `${url}?${name}=${value}` : `${url}&${name}=${value}`;
    this.queryCount++;
    return url;
  }

  private getBaseUrl(isMeta: boolean): string {
    let mode: 'dev' | 'qa' | 'uat' | 'staging' | 'preProd' | 'prod';

    mode = environment.CURRENT_MODE as typeof mode;

    const baseUrlBlock = baseUrls[mode];
    const baseUrl = isMeta ? baseUrlBlock.BASE_META_URL : baseUrlBlock.BASE_DATA_URL;

    return baseUrl;
  }

  private propertiesValidation(requestType: Array<RequestType>, parameterList: Array<Parameter>): boolean {
    let request_type = new Array<any>();
    let parameter_name = new Array<any>();

    requestType.forEach(rType => {
      request_type.push(rType.name);
    });

    parameterList.forEach(parameterName => {
      parameter_name.push(parameterName.name);

    });

    const result = parameter_name.every(val => request_type.includes(val));
    return result;
  }

  private setFormData(requestType: RequestType, parameter: Parameter, formData: FormData): FormData {
    if (requestType.type === 'array') {
      for (let i = 0; parameter.value.length > i; i++) {
        formData.append(parameter.name, parameter.value[i], parameter.value[i].name);
      }
    } else {
      formData.append(parameter.name, parameter.value);
    }
    return formData;
  }
}
