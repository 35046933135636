import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MemberPortalLoginUserResponse, OtpGeneratedResponse, MemberPortalPersonVerifyResponse } from '@core/models/universal';
import { AuthService } from '@core/services/auth.service';
import { MstDocumentService } from '@core/services/mst-document.service';
import { ConfirmationMessageService } from '@core/services/confirmation-message.service';
import { UiService } from '@core/services/ui.service';
import { Parameter } from '@core/util/model/api-service/parameter.model';
import { AppResponse } from '@core/util/model/response-models/app-response';
import { GlobalMasterService } from '@core/util/services/global-master.service';
import { ValidationService } from '@core/util/services/validation.service';
import * as otpJsonData from '../../../../../assets/otp/otp.json';
import {Location} from '@angular/common';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';

type UiSections ="INVITATION" | 'OTP' | 'CREATEUSER'
@Component({
  selector: 'app-create-portal-member-landing',
  templateUrl: './create-portal-member-landing.component.html',
  styleUrls: ['./create-portal-member-landing.component.scss']
})
export class CreatePortalMemberLandingComponent implements OnInit {

  matcher = new conirmPasswordErrorStateMatcher();
  public sentOTPCount =0;
  public personVerificationForm:FormGroup;
  public loginOTPForm:FormGroup;
  public createUserForm:FormGroup;
  public isLoginInvitationLoading:boolean;
  public verifyRefNoLoading =false;
  public refNoVerified =false;
  public language ='ENG'
  public loginLoading =false;
  public resendLoading =false;
  public otpValidationLoading = false;
  public otpVerified =false;
  public refNoVerifiedData:MemberPortalPersonVerifyResponse;
  public passwordHide =true;
  public otpGenratedInterval: number;
  public isOtpGenerated: boolean;
  // First active section is INVITATION
  public activeScreen: UiSections ="INVITATION"

  constructor(
    private uiService: UiService,
    private formBuilder: FormBuilder,
    private _location: Location,
    private authService:AuthService,
    private validationService: ValidationService,
    private globalMasterService: GlobalMasterService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.initpersonVerificationForm();
    this.initCreateUserForm()
    this.initloginOTPForm()
  }

  get memberpersonVerificationFormControls(){
    return this.personVerificationForm.controls
  }

  get createUserControls(){
    return this.createUserForm.controls
  }

  get otpFormControls(){
    return this.loginOTPForm.controls
  }


  private initpersonVerificationForm(){
    this.personVerificationForm = this.formBuilder.group({
      personRefNo: [null, { validators: this.validationService.getValidations('any_ref_no') }],
    })
  }

  private initloginOTPForm(){
    this.loginOTPForm = this.formBuilder.group({
      loginOtp: [null, { validators: this.validationService.getValidations('login_otp') }]
    })
  }

  private initCreateUserForm(){
    this.createUserForm = this.formBuilder.group({
      password: [null, { validators: this.validationService.getValidations('password') }],
      confirmPassword:[null ,{validators: this.validationService.getValidations('password') }],
      username: [null, { validators: this.validationService.getValidations('username') }],
    }, { validators: this.checkPasswords })
  }

  public validateRefNo(){
    this.otpGenratedInterval = otpJsonData.LOGIN_OTP_INTERVAL;
    const parameterList = new Array<Parameter>();

    this.verifyRefNoLoading =true;

    parameterList.push({name:'ref_no',value:+this.personVerificationForm.value.personRefNo})
    this.globalMasterService.executeApiRequest<AppResponse<MemberPortalPersonVerifyResponse>>(
     'MemberPortalAccountCreate_verifyPerson_1',
     parameterList)
     .subscribe((res: AppResponse<MemberPortalPersonVerifyResponse>) => {
       if (!res.error) {
        this.refNoVerified = true;
        this.refNoVerifiedData =res.data
        this.language =this.refNoVerifiedData.language_code
        // Change Active Screen
        this.activeScreen ='OTP'
        this.verifyRefNoLoading =false;
        this.isOtpGenerated = true;
        this.setAndStartOTPTimer();
        this.uiService.showSuccessToastr('Success', 'Reference Number Validated Successfully');
       } else {
         this.verifyRefNoLoading =false;
         this.uiService.showErrorToastr('Error', res.error.error.detail); //test 44595
       }
     }),(error =>{
      this.verifyRefNoLoading =false;
      this.uiService.showErrorToastr('Error', 'Something Went Wrong');
     });
  }

public backClick(){
    this._location.back();
 }

  /** Calling when veryfying the otp.*/
  public  callVerifYOTP(){
    const parameterList = new Array<Parameter>();
     const request ={
      contact_value: null,
      otp_type: 1,
      otp_value: this.loginOTPForm.value.loginOtp
    }

    parameterList.push({name:'request',value:request})
    this.otpValidationLoading =true;
    this.globalMasterService.executeApiRequest<AppResponse<MemberPortalLoginUserResponse>>(
     'MemberPortalOtpManage_verifyOtp_1',
     parameterList)
     .subscribe((res: AppResponse<OtpGeneratedResponse>) => {
       if (!res.error) {
         this.otpValidationLoading =false;
          this.activeScreen ="CREATEUSER"
        //  this.selectedAddress =res?.data
        //   this.patchValues(this.selectedAddress);
        this.uiService.showSuccessToastr('Success', 'OTP Verified');
       } else {
        this.otpValidationLoading =false;
         this.uiService.showErrorToastr('Error', res.error.detail);
       }
     });
  }

  public resendOtp(){
    this.otpGenratedInterval = otpJsonData.LOGIN_OTP_INTERVAL;
    if(this.refNoVerifiedData?.mobile_otp_generate?.contact_value && this.refNoVerifiedData?.email_otp_generate?.contact_value){
      const mobileParameterList = new Array<Parameter>();

      const mobileRequest ={
        contact_value:  this.refNoVerifiedData.mobile_otp_generate.contact_value,
        otp_type: 1,
        message_contact_type_code: 'SMS',
        admin_user: false,	
        member_id: this.refNoVerifiedData.mobile_otp_generate.member_id
        
     }

     mobileParameterList.push({name:'request',value:mobileRequest})
    const mobileObs=this.globalMasterService.executeApiRequest<AppResponse<MemberPortalLoginUserResponse>>(
    'MemberPortalOtpManage_create_1',
    mobileParameterList)

    const emailParameterList = new Array<Parameter>();
    const emailRequest ={
        contact_value:  this.refNoVerifiedData.email_otp_generate.contact_value,
        otp_type: 1,
        message_contact_type_code: 'EM',
        admin_user: false,	
        member_id: this.refNoVerifiedData.email_otp_generate.member_id
     }
     emailParameterList.push({name:'request',value:emailRequest})

     const emailObs =this.globalMasterService.executeApiRequest<AppResponse<MemberPortalLoginUserResponse>>(
      'MemberPortalOtpManage_create_1',
      emailParameterList)

    this.resendLoading =true;
    forkJoin([mobileObs, emailObs]).subscribe(
      (response) => {
        this.resendLoading =false;
        this.isOtpGenerated = true;
        this.sentOTPCount +=1;
        this.setAndStartOTPTimer();
        this.uiService.showSuccessToastr('Success', 'OTP Sent to Mobile & Email' );
      })
    }
    else if(this.refNoVerifiedData?.mobile_otp_generate?.contact_value){
      const parameterList = new Array<Parameter>();

      const request ={
        contact_value:  this.refNoVerifiedData.mobile_otp_generate.contact_value,
        otp_type: 1,
        message_contact_type_code: 'SMS',
        admin_user: false,	
        member_id: this.refNoVerifiedData.mobile_otp_generate.member_id
     }
     
     parameterList.push({name:'request',value:request})
     this.resendLoading =true;
     this.globalMasterService.executeApiRequest<AppResponse<MemberPortalLoginUserResponse>>(
      'MemberPortalOtpManage_create_1',
      parameterList)
      .subscribe((res: AppResponse<MemberPortalLoginUserResponse>) => {
        if (!res.error) {
          this.resendLoading =false;
          this.isOtpGenerated = true;
          this.sentOTPCount +=1;
          this.setAndStartOTPTimer();
          this.uiService.showSuccessToastr('Success', 'OTP Sent to Mobile' );
        } else {
         this.resendLoading =false;
          this.uiService.showErrorToastr('Error', res.error.detail);
        }
      });
    }

    else if(this.refNoVerifiedData?.email_otp_generate?.contact_value){
      const parameterList = new Array<Parameter>();

      const request ={
        contact_value:  this.refNoVerifiedData.email_otp_generate.contact_value,
        otp_type: 1,
        message_contact_type_code: 'EM',
        admin_user: false,	
        member_id: this.refNoVerifiedData.email_otp_generate.member_id
     }
  
     parameterList.push({name:'request',value:request})
     this.resendLoading =true;
     this.globalMasterService.executeApiRequest<AppResponse<MemberPortalLoginUserResponse>>(
      'MemberPortalOtpManage_create_1',
      parameterList)
      .subscribe((res: AppResponse<MemberPortalLoginUserResponse>) => {
        if (!res.error) {

          this.resendLoading =false;
          this.isOtpGenerated = true;
          this.sentOTPCount +=1;
          this.setAndStartOTPTimer();
          this.uiService.showSuccessToastr('Success', 'OTP Sent to Email Address' );
        } else {
         this.resendLoading =false;
          this.uiService.showErrorToastr('Error', res.error.detail);
        }
      });
    }
  }


  public loginClick(){
 
    this.createUserForm.markAllAsTouched()
    if(this.createUserForm.invalid){
      return
    }

    const parameterList = new Array<Parameter>();

 
     const request ={
      confirm_password: this.createUserForm.value.confirmPassword,
      is_otp_verify: true,
      language_code: this.language,
      password: this.createUserForm.value.password,
      person_ref_no: this.refNoVerifiedData.person_ref_no,
      username: this.createUserForm.value.username
    }

    parameterList.push({name:'request',value:request})
    this.loginLoading =true;
    this.globalMasterService.executeApiRequest<AppResponse<MemberPortalLoginUserResponse>>(
     'MemberPortalUserInvitation_createUser_1',
     parameterList)
     .subscribe((res: AppResponse<MemberPortalLoginUserResponse>) => {
       if (!res.error) {
         this.loginLoading =false;
         this.authService.loginfromInvitation()
        //  this.selectedAddress =res?.data
        //   this.patchValues(this.selectedAddress);
        this.uiService.showSuccessToastr('Success', 'User Created Successfully');
       } else {
        this.loginLoading =false;
         this.uiService.showErrorToastr('Error', res?.error?.error?.detail);
       }
     });
  }

    /**
  * Starting and setting otp Resend Timer.
  *
  * @param timerStartTime - The Start time, defaults to value from config
  *
  */
     private setAndStartOTPTimer(timerStartTime = otpJsonData.LOGIN_OTP_INTERVAL) {
      this.otpGenratedInterval = timerStartTime;
      const interval = setInterval(() => {
        if (this.otpGenratedInterval === 0) {
          clearInterval(interval);
        }
        this.otpGenratedInterval = this.otpGenratedInterval - 1;
        if (this.otpGenratedInterval <= 0) {
        }
      }, 1000);
    }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    const password = group.get('password').value;
    const confirmPassword = group.get('confirmPassword').value;
    if(group.get('confirmPassword').dirty){
      return password?.trim() === confirmPassword?.trim() ? null : { notSame: true }   
    }
    else{
      return null
    }
     
  }
  
}


export class conirmPasswordErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    // return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    const invalidParent = !!(
      control
      && control.parent
      && control.parent.invalid
      && (control.dirty || control.touched ||  control.parent.hasError('notSame') || isSubmitted))
       return (invalidParent);
  }
}