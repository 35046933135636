import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { delay } from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class ServerLoadService {
    private callProgress = new Subject<boolean>();

    sendProgress(status: boolean) {
      if (status) {
        this.callProgress.next(true);
      } else {
        this.callProgress.next(false);
      }
    }

    getProgress(): Observable<any> {
        return this.callProgress.pipe(delay(0));
    }
}
