import { CoreModule } from './core/core.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler, APP_INITIALIZER, Injector } from '@angular/core';

import { AppRoutingModule } from 'src/app/app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from '@block/root/app.component';
import { NotFoundComponent } from '@block/pages/not-found/not-found.component';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { AuthService } from '@core/services/auth.service';
import { UiService } from '@core/services/ui.service';
import { GlobalErrorHandlerService } from '@core/services/global-error-handler.service';
import { ApiInterceptorService } from '@core/services/api-interceptor.service';
import { ServerLoadService } from '@core/services/server-load.service';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { MAT_DATE_LOCALE } from '@angular/material/core';
import { DatePipe } from '@angular/common';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { KeycloakAngularModule, KeycloakBearerInterceptor, KeycloakOptions, KeycloakService } from 'keycloak-angular';
import { PermissionService } from '@core/services/permission.service';

import * as keycloak from '../assets/configs/keycloak-configs.json';
import { KeycloakOnLoad } from 'keycloak-js';
import { environment } from 'src/environments/environment';
import baseUrls from 'src/assets/urls/base-urls.json';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SelfEmploymentProfileLayoutComponent } from '@block/pages/layout/self-employment-profile-layout/self-employment-profile-layout.component';
import { SelfEmploymentProfileBreadcrumbComponent } from '@block/pages/layout/self-employment-profile-layout/self-employment-profile-breadcrumb/self-employment-profile-breadcrumb.component';
import { MemberProfileLayoutComponent } from '@block/pages/layout/member-profile-layout/member-profile-layout.component';
import { MemberProfileBreadcrumbComponent } from '@block/pages/layout/member-profile-layout/member-profile-breadcrumb/member-profile-breadcrumb.component';

export function authInitializer(Keyloack: KeycloakService, permissionService: PermissionService) {
  const currentMode = environment.CURRENT_MODE

  const options: KeycloakOptions = {
    config: {
      realm: keycloak.KEYCLOAK.realm,
      url: baseUrls[environment.CURRENT_MODE].AUTH_SERVER_URL,
      clientId: keycloak.KEYCLOAK.clientId,
    },
    initOptions: {
      onLoad: keycloak.KEYCLOAK.initOptions.onLoad as KeycloakOnLoad,
      silentCheckSsoRedirectUri:
      window.location.origin + '/assets/silent-check-sso.html',
      checkLoginIframe: keycloak.KEYCLOAK.initOptions.checkLoginIframe
    },
    bearerExcludedUrls: currentMode ==='prod' || 'staging' ? keycloak.KEYCLOAK.bearerExcludedUrls : [...keycloak.KEYCLOAK.bearerExcludedUrls,'meta']
  }
  return (): Promise<any> => Keyloack.init(options).then((authenticated) => {
    if (authenticated) {
      const username = Keyloack.getKeycloakInstance().tokenParsed['preferred_username'];
      return new Promise((resolve) => {
        // const loadUserPermissions = permissionService.loadUserPermissions(username);
        // const loadStaticPermissions = permissionService.loadStaticPermissions('./assets/configs/permission-configs.json');
        // Promise.all([loadUserPermissions, loadStaticPermissions]).then((values) => {

        // });
        resolve('');
      });
    }
  }).catch(() => {
    alert('failed to initialize');
  });
}


// **
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
// **


@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent,
    MemberProfileLayoutComponent,
    SelfEmploymentProfileLayoutComponent,
    MemberProfileBreadcrumbComponent,
    SelfEmploymentProfileBreadcrumbComponent,
  ],
  imports: [
    RouterModule,
    CoreModule,
    // FirstPhaseGlobalComponentsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    NgxMaterialTimepickerModule,
    KeycloakAngularModule,
    // **
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
    // **

  ],
  providers: [
    AuthService,
    UiService,
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService },
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptorService, multi: true, deps: [ServerLoadService,Injector] },
    ServerLoadService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    DatePipe,
    KeycloakService,
    PermissionService,
    { provide: APP_INITIALIZER, useFactory: authInitializer, multi: true, deps: [KeycloakService, PermissionService] },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: KeycloakBearerInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

