<!-- Issue in back button -->
<button mat-icon-button class="back-button" color="primary"  (click)="backClick()" aria-label="Example icon button with a home icon">
    <mat-icon>arrow_back_ios</mat-icon>
  </button>
<div class="main-div">
    <mat-card class="invitaion-card">
        <!-- <h4 class="login-invitation-heading">Member Login Invitation</h4> -->
        <div class="mt-4">
            <form [formGroup]="loginInvitationForm" #f="ngForm" *ngIf="activeScreen ==='INVITATION'"
                (ngSubmit)="f.form.valid && onValidateInvitationSubmit()" autocomplete="off" class="w-100 mt-3">
              
                <div class="w-100 mt-2" fxLayout="column" fxLayoutGap="1rem" fxLayoutAlign="start start">
                    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="0rem" class="w-100">
                        <span class="otp-heading">Please Enter a valid login invitation here</span >
                        <mat-form-field class="w-100 mt-3" appearance="outline">
                            <mat-label>Login Invitation</mat-label>
                            <input type="text" id="loginInvitation" name="loginInvitation" matInput required
                                maxlength="200" formControlName="loginInvitation">
                            <mat-error *ngIf="memberLoginInvitationFormControls?.loginInvitation?.errors">
                                • {{memberLoginInvitationFormControls?.loginInvitation?.errors |
                                etfbValidation:'login_invitation'}}
                            </mat-error>
                        </mat-form-field>
                        <div fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1rem">
                            <button mat-raised-button type="submit" class="validate-button"
                                *ngIf="!isLoginInvitationLoading && !isInvitationVerified" color="accent">Validate</button>
                            <span class="material-icons verified-logo" matTooltip="Verified"
                                [hidden]="!isInvitationVerified">
                                verified_user
                            </span>
                            <mat-spinner [diameter]="40" *ngIf="isLoginInvitationLoading"></mat-spinner>
                            <!-- <button mat-stroked-button type="submit" class="validate-button" color="primary">Resend
                                Invitation</button> -->
                        </div>
                    </div>
                </div>
            </form>
            <form [formGroup]="loginOTPForm" #f2="ngForm" *ngIf="activeScreen ==='OTP'"
            (ngSubmit)="f2.form.valid && callVerifYOTP()" autocomplete="off" class="w-100 mt-3">

            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="0rem" class="w-100 mt-2">
                <span class="otp-heading">Please Enter the One-Time Password to verify your account</span >
                <mat-form-field class="w-100 mt-3" appearance="outline">
                    <mat-label>Enter OTP</mat-label>
                    <input type="text" id="loginOtp" name="loginOtp" matInput required
                        formControlName="loginOtp">
                    <mat-error *ngIf="otpFormControls?.loginOtp?.errors">
                        • {{otpFormControls?.loginOtp?.errors |
                        etfbValidation:'login_otp'}}
                    </mat-error>
                </mat-form-field>
                <div  fxLayout="column" fxLayoutGap="1rem" fxLayoutGap="1rem" fxLayoutAlign="center center">
                    <button mat-raised-button type="submit" class="validate-button" *ngIf="!isOtpValidationLoading && !otpVerified"
                    color="accent">Validate</button>
                    <span class="material-icons verified-logo" matTooltip="Verified" [hidden]="!otpVerified">
                        verified_user
                      </span>
                    <mat-spinner [diameter]="35" *ngIf="isOtpValidationLoading"></mat-spinner>
                    <span class="pwd-reset">Didn't get your password? Click to Resend</span>
                    <button mat-stroked-button type="button" [disabled]="sentOTPCount >=3"  *ngIf="!isResendLoading" [hidden]="isOtpWaitTimerDisplayed" class="validate-button" (click)="resendOtp()"
                    color="primary">Resend OTP</button>
                    <mat-spinner [diameter]="35" *ngIf="isResendLoading"></mat-spinner> 
                    <h5 class="label-light-grey" *ngIf="isOtpWaitTimerDisplayed" matTooltip="OTP Regenerate Waiting Time">
                        {{otpGenratedInterval}}s</h5>
                </div>
     
            </div>
            </form>
            <form [formGroup]="createUserForm" #f3="ngForm" (ngSubmit)="loginClick()"  *ngIf="activeScreen ==='CREATEUSER'"
                autocomplete="off" class="w-100 mt-3">

                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="0rem" class="w-100">
                    <span class="otp-heading">Please Enter your new Account details</span >
                    <mat-form-field class="w-100 mt-2" appearance="outline">
                        <mat-label>Username</mat-label>
                        <input type="text" id="username" name="username" matInput required maxlength="20"
                            formControlName="username">
                        <mat-error *ngIf="createUserControls?.username?.errors">
                            • {{createUserControls?.username?.errors |
                            etfbValidation:'username'}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>Password</mat-label>
                        <input  [type]="passwordHide ? 'password' : 'text'" id="password" name="password" matInput required maxlength="20"
                            formControlName="password">
                            <!-- <button mat-icon-button matSuffix type="button" (click)="passwordHide = !passwordHide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passwordHide">
                                <mat-icon>{{passwordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button> -->
                        <mat-error *ngIf="createUserControls?.password?.errors">
                            • {{createUserControls?.password?.errors |
                            etfbValidation:'password'}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-100 mt-3" appearance="outline">
                        <mat-label>Confirm Password</mat-label>
                        <input type="password" id="password" name="confirmPassword" matInput required maxlength="20"
                            [errorStateMatcher]="matcher" formControlName="confirmPassword">
                            <mat-error *ngIf="createUserForm.get('confirmPassword').hasError('required')">
                                •  Required
                            </mat-error>
                            <mat-error *ngIf="createUserForm.hasError('notSame')">
                            • Passwords do not match
                        </mat-error>
                    
                        <!-- <mat-error *ngIf="createUserControls?.confirmPassword?.errors">
                                • {{createUserControls?.confirmPassword?.errors |
                                etfbValidation:'password'}}
                            </mat-error> -->
                    </mat-form-field>

                    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1rem" class="w-100 mt-2">
                        <label class="language-label" id="example-radio-group-label">Choose Your Language</label>
                        <mat-radio-group fxLayout="row" fxLayoutGap="1rem" aria-label="Select an option"
                            [(ngModel)]="language" [ngModelOptions]="{standalone: true}">
                            <mat-radio-button color="primary" value="ENG">English</mat-radio-button>
                            <mat-radio-button color="primary" value="SIN">Sinhala</mat-radio-button>
                            <mat-radio-button color="primary" value="TAM">Tamil</mat-radio-button>
                        </mat-radio-group>
                        <button mat-raised-button type="submit" class="validate-button" *ngIf="!isLoginLoading"
                             color="primary">Continue</button>
                        <mat-spinner [diameter]="35" *ngIf="isLoginLoading"></mat-spinner>
                    </div>
                </div>


            </form>
        </div>
    </mat-card>
</div>