
import {  Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MemberPortalLoginUserResponse, MemberPortalUserInvitationResponse, OtpGeneratedResponse } from '@core/models/universal';
import { MemberPortalUserInvitationVerifyRequest } from '@core/models/universal/global-login-create-user/member-portal-user-invitation-verify-request.model';
import { AuthService } from '@core/services/auth.service';
import { UiService } from '@core/services/ui.service';
import { Parameter } from '@core/util/model/api-service/parameter.model';
import { AppResponse } from '@core/util/model/response-models/app-response';
import { GlobalMasterService } from '@core/util/services/global-master.service';
import { ValidationService } from '@core/util/services/validation.service';
import * as otpJsonData from '../../../../../assets/otp/otp.json';
import {Location} from '@angular/common';
import { forkJoin, Observable } from 'rxjs';
import { ServerInBuiltError } from '@core/util/model/api-service/ServerInBuiltError.model';

// main Sections of the ui
type UiSections ="INVITATION" | 'OTP' | 'CREATEUSER'
@Component({
  selector: 'app-member-login-invitation',
  templateUrl: './member-login-invitation.component.html',
  styleUrls: ['./member-login-invitation.component.scss']
})
export class MemberLoginInvitationComponent implements OnInit {

  matcher = new conirmPasswordErrorStateMatcher();
  public sentOTPCount =0;
  public loginInvitationForm:FormGroup;
  public loginOTPForm:FormGroup;
  public createUserForm:FormGroup;
  public isLoginInvitationLoading =false;
  public isInvitationVerified =false;
  public language ='ENG'
  public isLoginLoading = false;
  public isOtpValidationLoading = false;
  public otpVerified =false;
  public invitationVerifiedData:MemberPortalUserInvitationResponse;
  public passwordHide =true;
  public otpGenratedInterval: number;
  public isOtpWaitTimerDisplayed: boolean;
  public isResendLoading = false;
 
  // First active section is INVITATION
  public activeScreen: UiSections ="INVITATION"

  constructor(
    private uiService: UiService,
    private formBuilder: FormBuilder,
    private authService:AuthService,
    private validationService: ValidationService,
    private globalMasterService: GlobalMasterService,
    public dialog: MatDialog,
    private _location: Location
  ) { }

  ngOnInit(): void {
    // this.initLoginForm();
    this.initloginInvitationForm();
    this.initCreateUserForm()
    this.initloginOTPForm()
  }

  get memberLoginInvitationFormControls(){
    return this.loginInvitationForm.controls
  }

  get createUserControls(){
    return this.createUserForm.controls
  }

  get otpFormControls(){
    return this.loginOTPForm.controls
  }

  onValidateInvitationSubmit(){
    this.validateloginInvitation()
  }

  private initloginInvitationForm(){
    this.loginInvitationForm = this.formBuilder.group({
      loginInvitation: [null, { validators: this.validationService.getValidations('login_invitation') }],
    })
  }

  public backClick(){
    this._location.back();
 }


  private initloginOTPForm(){
    this.loginOTPForm = this.formBuilder.group({
      loginOtp: [null, { validators: this.validationService.getValidations('login_otp') }]
    })
  }

  private initCreateUserForm(){
    this.createUserForm = this.formBuilder.group({
      password: [null, { validators: this.validationService.getValidations('password') }],
      confirmPassword:[null ,{validators: this.validationService.getValidations('password') }],
      username: [null, { validators: this.validationService.getValidations('username') }],
    }, { validators: this.checkPasswords })
  }

  private validateloginInvitation(){
    this.otpGenratedInterval = otpJsonData.LOGIN_OTP_INTERVAL;
    const parameterList = new Array<Parameter>();

    const request:MemberPortalUserInvitationVerifyRequest ={
      otp_value:this.loginInvitationForm.value.loginInvitation
    }
    this.isLoginInvitationLoading =true;

    parameterList.push({name:'request',value:request})
    this.globalMasterService.executeApiRequest<AppResponse<MemberPortalUserInvitationResponse>>(
     'MemberPortalUserInvitation_verify_1',
     parameterList)
     .subscribe((res: AppResponse<MemberPortalUserInvitationResponse>) => {
       if (!res.error) {
        this.isInvitationVerified = true;
        this.invitationVerifiedData =res.data
        // Change Active Screen
        this.activeScreen ='OTP'
        this.isLoginInvitationLoading =false;
        this.isOtpWaitTimerDisplayed = true;
        this.setAndStartOTPTimer();

        this.uiService.showSuccessToastr('Success', 'Invitation Validated Successfully');
       } else {
         this.isLoginInvitationLoading =false;
         this.uiService.showErrorToastr('Error', res?.error?.error?.detail);
       }
     }),(error =>{
      this.uiService.showErrorToastr('Error', 'Something Went Wrong');
     });
  }

  
  public  callVerifYOTP(){
    const parameterList = new Array<Parameter>();

 
     const request ={
      contact_value: null,
      otp_type: 7,
      otp_value: this.loginOTPForm.value.loginOtp
    }

    parameterList.push({name:'request',value:request})
    this.isOtpValidationLoading =true;
    this.globalMasterService.executeApiRequest<AppResponse<MemberPortalLoginUserResponse>>(
     'MemberPortalOtpManage_verifyOtp_1',
     parameterList)
     .subscribe((res: AppResponse<OtpGeneratedResponse>) => {
       if (!res.error) {
        this.isOtpValidationLoading =false;
        this.activeScreen ="CREATEUSER"
        this.uiService.showSuccessToastr('Success', 'OTP Verified');
       } else {
        this.isOtpValidationLoading =false;
         this.uiService.showErrorToastr('Error', res?.error?.error?.detail);
       }
     });
  }

  public resendOtp() {
    this.otpGenratedInterval = otpJsonData.LOGIN_OTP_INTERVAL;
    this.sentOTPCount += 1
    //if mobile and Email Both available
    if (this.invitationVerifiedData?.mobile_otp_generate?.contact_value && this.invitationVerifiedData?.email_otp_generate?.contact_value) {

      const mobileObs =  this.getResendOTPObservable(7, 'SMS', this.invitationVerifiedData.mobile_otp_generate.contact_value, false, this.invitationVerifiedData.mobile_otp_generate.member_id)

      const emailObs = this.getResendOTPObservable(7, 'EM', this.invitationVerifiedData.email_otp_generate.contact_value, false, this.invitationVerifiedData.email_otp_generate.member_id)

      this.isResendLoading = true;
      forkJoin([mobileObs, emailObs]).subscribe(
        (response) => {
          this.isResendLoading = false;
          this.setAndStartOTPTimer();
          this.uiService.showSuccessToastr('Success', 'OTP Sent to Mobile & Email');
        })
    }
    //if only mobile is available
    else if (this.invitationVerifiedData?.mobile_otp_generate?.contact_value) {
      this.isResendLoading = true;
      this.getResendOTPObservable(4, 'SMS', this.invitationVerifiedData.mobile_otp_generate.contact_value, false, this.invitationVerifiedData.mobile_otp_generate.member_id)
        .subscribe((res: AppResponse<MemberPortalLoginUserResponse>) => {
          if (!res.error) {
            this.isResendLoading = false;
            this.setAndStartOTPTimer();
            this.uiService.showSuccessToastr('Success', 'OTP Sent to Mobile');
          } else {
            this.isResendLoading = false;
            this.uiService.showErrorToastr('Error', res.error.detail);
          }
        });
    }
   //if only Email is available
    else if (this.invitationVerifiedData?.email_otp_generate?.contact_value) {
      this.isResendLoading = true;
      this.getResendOTPObservable(4, 'EM', this.invitationVerifiedData.email_otp_generate.contact_value, false, this.invitationVerifiedData.email_otp_generate.member_id)
        .subscribe((res: AppResponse<MemberPortalLoginUserResponse>) => {
          if (!res.error) {
            this.isResendLoading = false;
            this.setAndStartOTPTimer();
            this.uiService.showSuccessToastr('Success', 'OTP Sent to Email Address');
          } else {
            this.isResendLoading = false;
            this.uiService.showErrorToastr('Error', res.error.detail);
          }
        });
    }
  }


  public loginClick(){
 
    this.createUserForm.markAllAsTouched()
    if(this.createUserForm.invalid){
      return
    }

    const parameterList = new Array<Parameter>();

     const request ={
      confirm_password: this.createUserForm.value.confirmPassword,
      is_otp_verify: true,
      language_code: this.language,
      password: this.createUserForm.value.password,
      person_ref_no: null,
      user_invitation_id: this.invitationVerifiedData?.user_invitation_id,
      username: this.createUserForm.value.username
    }

    parameterList.push({name:'request',value:request})
    this.isLoginLoading =true;
    this.globalMasterService.executeApiRequest<AppResponse<MemberPortalLoginUserResponse>>(
     'MemberPortalUserInvitation_createUser_1',
     parameterList)
     .subscribe((res: AppResponse<MemberPortalLoginUserResponse>) => {
       if (!res.error) {
         this.isLoginLoading =false;
         this.authService.loginfromInvitation()
        this.uiService.showSuccessToastr('Success', 'User Created Successfully');
       } else {
        this.isLoginLoading =false;
         this.uiService.showErrorToastr('Error', res?.error?.error?.detail);
       }
     });
  }

   /**
  * Starting and setting otp Resend Timer.
  *
  * @param timerStartTime - The Start time, defaults to value from config
  *
  */
    private setAndStartOTPTimer(timerStartTime = otpJsonData.LOGIN_OTP_INTERVAL) {
      this.otpGenratedInterval = timerStartTime;
      this.isOtpWaitTimerDisplayed = true;
      const interval = setInterval(() => {
        if (this.otpGenratedInterval === 0) {
          clearInterval(interval);
        }
        this.otpGenratedInterval = this.otpGenratedInterval - 1;
        if (this.otpGenratedInterval <= 0) {
          this.isOtpWaitTimerDisplayed = false;
        }
      }, 1000);
    }
  
    /**
     * Resending the OTP to mobile or Email.
     *
     * @param otpType - OTP type number (for backend)
     * @param otpTypeCode - OTP type Code ('EM' || 'MB')
     * @param contactTypeCode - OTP type number (for backend)
     * @param contactValue - Contact Value 
     * @returns The observable for api call
     */
        private getResendOTPObservable(otpType:number,contactTypeCode:string,contactValue:string,adminUser=false,memberId:string |number) : Observable<AppResponse<any> | ServerInBuiltError>  {
          const parameterList = new Array<Parameter>();
          const request ={
            contact_value:  contactValue,
            otp_type: otpType,
            message_contact_type_code: contactTypeCode,
            admin_user: adminUser,	
            member_id: memberId
         }
      
         parameterList.push({name:'request',value:request})
        //  this.isResendLoading =true;
         return this.globalMasterService.executeApiRequest<AppResponse<MemberPortalLoginUserResponse>>(
          'MemberPortalOtpManage_create_1',
          parameterList)
      }
  

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    const password = group.get('password').value;
    const confirmPassword = group.get('confirmPassword').value;
    if(group.get('confirmPassword').dirty){
      return password?.trim() === confirmPassword?.trim() ? null : { notSame: true }   
    }
    else{
      return null
    }
     
  }
  
}


export class conirmPasswordErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    // return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    const invalidParent = !!(
      control
      && control.parent
      && control.parent.invalid
      && (control.dirty || control.touched ||  control.parent.hasError('notSame') || isSubmitted))
       return (invalidParent);
  }
}