<!-- Issue in back button -->
<button mat-icon-button class="back-button" color="primary"  (click)="backClick()" aria-label="Example icon button with a home icon">
    <mat-icon>arrow_back_ios</mat-icon>
  </button>
<div class="main-div">
    <mat-card class="invitaion-card">
        <!-- <h4 class="login-invitation-heading">Member Login Invitation</h4> -->

        <div class="mt-4">
            <form [formGroup]="userNameForm" #f="ngForm" *ngIf="activeScreen ==='INVITATION'"
                (ngSubmit)="f.form.valid && onValidateInvitationSubmit()" autocomplete="off" class="w-100 mt-3">
              
                <div class="w-100 mt-2" fxLayout="column" fxLayoutGap="1rem" fxLayoutAlign="start start">
                    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="0rem" class="w-100">
                        <span class="otp-heading">Please Enter your username</span >
                        <mat-form-field class="w-100 mt-3" appearance="outline">
                            <mat-label>Username</mat-label>
                            <input type="text" id="username" name="username" matInput required
                                maxlength="200" formControlName="username">
                            <mat-error *ngIf="usernameFormControls?.username?.errors">
                                • {{usernameFormControls?.username?.errors |
                                etfbValidation:'login_invitation'}}
                            </mat-error>
                        </mat-form-field>
                        <div fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1rem">
                            <button mat-raised-button type="submit" class="validate-button"
                                *ngIf="!usernameLoading && !invitationVerified" color="accent">Validate</button>
                            <span class="material-icons verified-logo" matTooltip="Verified"
                                [hidden]="!invitationVerified">
                                verified_user
                            </span>

                            <button mat-stroked-button *ngIf="!usernameLoading" type="button" color="primary" (click)="activeScreen ='FORGOTUSERNAME'"> forgot User Name </button>
                            <mat-spinner [diameter]="40" *ngIf="usernameLoading"></mat-spinner>
                            <!-- <button mat-stroked-button type="submit" class="validate-button" color="primary">Resend
                                Invitation</button> -->
                        </div>
                    </div>
                </div>
            </form>
            <form [formGroup]="createUserForm" #f3="ngForm" (ngSubmit)="loginClick()"  *ngIf="activeScreen ==='RESETUSER'"
                autocomplete="off" class="w-100 mt-3">

                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="0rem" class="w-100">
                    <span class="otp-heading">Please Enter the OTP and the New password</span >
                    <mat-form-field class="w-100 mt-2" appearance="outline">
                        <mat-label>Enter OTP</mat-label>
                        <input type="text" id="resetOTP" name="resetOTP" matInput required maxlength="20"
                            formControlName="resetOTP">
                        <mat-error *ngIf="createUserControls?.resetOTP?.errors">
                            • {{createUserControls?.resetOTP?.errors |
                            etfbValidation:'login_otp'}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-100" appearance="outline">
                        <mat-label>New Password</mat-label>
                        <input  [type]="passwordHide ? 'password' : 'text'" id="password" name="password" matInput required maxlength="20"
                            formControlName="password">
                            <!-- <button mat-icon-button matSuffix type="button" (click)="passwordHide = !passwordHide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="passwordHide">
                                <mat-icon>{{passwordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                            </button> -->
                        <mat-error *ngIf="createUserControls?.password?.errors">
                            • {{createUserControls?.password?.errors |
                            etfbValidation:'password'}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="w-100 mt-3" appearance="outline">
                        <mat-label>Confirm New Password</mat-label>
                        <input type="password" id="password" name="confirmPassword" matInput required maxlength="20"
                            [errorStateMatcher]="matcher" formControlName="confirmPassword">
                            <mat-error *ngIf="createUserForm.get('confirmPassword').hasError('required')">
                                •  Required
                            </mat-error>
                            <mat-error *ngIf="createUserForm.hasError('notSame')">
                            • Passwords do not match
                        </mat-error>
                    
                        <!-- <mat-error *ngIf="createUserControls?.confirmPassword?.errors">
                                • {{createUserControls?.confirmPassword?.errors |
                                etfbValidation:'password'}}
                            </mat-error> -->
                    </mat-form-field>

                    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1rem" class="w-100 mt-2">
                        <button mat-raised-button type="submit" class="validate-button" *ngIf="!loginLoading"
                             color="primary">Continue</button>
                        <mat-spinner [diameter]="35" *ngIf="loginLoading"></mat-spinner>
                    </div>
                </div>


            </form>

            <form [formGroup]="forgotUserNameForm" #f="ngForm" *ngIf="activeScreen ==='FORGOTUSERNAME'"
            autocomplete="off" class="w-100 mt-3">
            
            <div class="w-100 mt-2" fxLayout="column" fxLayoutGap="1rem" fxLayoutAlign="start start">
                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="0rem" class="w-100">
                    <!-- <span class="otp-heading">Please Enter your username</span > -->
                        <mat-form-field class="w-100" appearance='outline'>
                            <mat-label>Person Ref. No.</mat-label>
                            <input matInput name="personRefNo" id="personRefNo" type="string"
                              formControlName="personRefNo" [required]="true" [maxlength]="12">
                            <mat-error
                              *ngIf="!forgotUserNameForm?.get('personRefNo').valid && forgotUserNameForm?.get('personRefNo').errors['required']">
                              • Person Ref. No. is required
                            </mat-error>
                            <mat-error
                              *ngIf="!forgotUserNameForm?.get('personRefNo').valid && forgotUserNameForm?.get('personRefNo').errors['pattern']">
                              • Only numeric values are allowed
                            </mat-error>
                          </mat-form-field>
                    <div fxLayoutAlign="center center" fxLayout="column" fxLayoutGap="1rem">
                        <button mat-raised-button type="submit" class="validate-button"
                            *ngIf="!usernameLoading && !invitationVerified" (click)="onForgotUserNameSubmit()" color="accent">Send Username</button>
                          
            <button mat-stroked-button  *ngIf="!usernameLoading" type="button" color="primary" (click)="activeScreen ='INVITATION'"> Back </button>

                        <span class="material-icons verified-logo" matTooltip="Verified"
                            [hidden]="!invitationVerified">
                            verified_user
                        </span>
                        <mat-spinner [diameter]="40" *ngIf="usernameLoading"></mat-spinner>
                        <!-- <button mat-stroked-button type="submit" class="validate-button" color="primary">Resend
                            Invitation</button> -->
                    </div>
                </div>
            </div>
        </form>
        </div>
    </mat-card>
</div>