import { GlobalTitleHeadingComponent } from './components/global-title-heading/global-title-heading.component';
import { GlobalHeadingLevelTwoComponent } from './components/global-heading-level-two/global-heading-level-two.component';
import { GlobalHeadingLevelThreeComponent } from './components/global-heading-level-three/global-heading-level-three.component';
import { GlobalHeadingLevelFourComponent } from './components/global-heading-level-four/global-heading-level-four.component';
import { NgModule } from '@angular/core';
import { ConfirmationMessageComponent } from '@core/components/confirmation-message/confirmation-message.component';
import { BlockModule } from '@block/block.module';
import { EtfbValidationPipe } from '@core/util/pipes/etfb-validation.pipe';
import { CoreRoutingModule } from '@core/core-routing.module';
import { GlobalMasterService } from '@core/util/services/global-master.service';
import { ValidationService } from '@core/util/services/validation.service';
import { SharedModule } from '@core/shared/shared.module';
import { EtfbCurrencyPipe } from '@core/util/pipes/etfb-currency.pipe';
import { EtfbDatePipe } from '@core/util/pipes/etfb-date.pipe';
import { CommonModule } from '@angular/common';

import { TranslateModule } from '@ngx-translate/core';

// In case captcha is needed
import { NgxCaptchaModule } from 'ngx-captcha';
import { GlobalCaptchaComponent } from './components/global-captcha/global-captcha.component';


@NgModule({
  declarations: [
    EtfbCurrencyPipe,
    EtfbDatePipe,
    ConfirmationMessageComponent,
    GlobalHeadingLevelFourComponent,
    GlobalHeadingLevelThreeComponent,
    GlobalHeadingLevelTwoComponent,
    GlobalTitleHeadingComponent,
    GlobalCaptchaComponent,
  ],
  imports: [
    CommonModule,
    CoreRoutingModule,
    SharedModule,
    BlockModule,
    // **
    TranslateModule,
    // NgxCaptchaModule
    // **
  ],
  providers: [
    GlobalMasterService,
    ValidationService,
  ],
  exports: [
    SharedModule,
    BlockModule,
    EtfbCurrencyPipe,
    EtfbDatePipe,
    EtfbValidationPipe,
    ConfirmationMessageComponent,
    GlobalHeadingLevelFourComponent,
    GlobalHeadingLevelThreeComponent,
    GlobalHeadingLevelTwoComponent,
    GlobalTitleHeadingComponent,

    // **
    TranslateModule,
    // NgxCaptchaModule
    // **
  ]
})

export class CoreModule { }
