<div fxLayout="column" fxLayoutAlign="center center" fxFlexFill class="not-found-page">
  <div class="image-div">
    <img src="assets/images/background/404.png" alt="404">
  </div>
  <div class="footer-div">
    <h2>Looks like you got lost</h2>
  </div>
  <div class="button-div">
    <a mat-raised-button routerLink="/" color="accent">Go Home</a>
  </div>
</div>
