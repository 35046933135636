import { SharedModule } from '@core/shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BlockRoutingModule } from '@block/block-routing.module';
import { LabelViewComponent } from '@block/components/label-view/label-view.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MemberLoginInvitationComponent } from './pages/landing/member-login-invitation/member-login-invitation.component';
import { MemberLoginConfirmationComponent } from './pages/landing/member-login-confirmation/member-login-confirmation.component';
import { CreatePortalMemberLandingComponent } from './pages/landing/create-portal-member-landing/create-portal-member-landing.component';
import { ResetPasswordComponent } from './pages/landing/reset-password/reset-password.component';


@NgModule({
  declarations: [LabelViewComponent, MemberLoginInvitationComponent, MemberLoginConfirmationComponent, CreatePortalMemberLandingComponent, ResetPasswordComponent],
  imports: [
    CommonModule,
    SharedModule,
    BlockRoutingModule
  ],
  exports: [LabelViewComponent]
})
export class BlockModule { }

