import { Validators } from '@angular/forms';
import { Injectable } from '@angular/core';
import * as validations from '@assets/configs/validation-configs.json';
import { ValidationContent } from '../model/validation/validation.model';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor() { }

  public getValidations(fieldCode: string): any {
    const validationList: any = [];
    const validationConfig = validations.VALIDATIONCONFIGS.find(v => v.code === fieldCode);

    if (!validationConfig) { return []; }

    validationConfig.validationList.forEach((v) => {
      return validationList.push(this.getValidationType(v));
    });

    return validationList;
  }

  private getValidationType(validationContent: ValidationContent): any {
    if (validationContent.validationType === 'required') {
      return Validators.required;
    }

    if (validationContent.validationType === 'email') {
      return Validators.email;
    }

    if (validationContent.validationType === 'max-length') {
      return Validators.maxLength(+validationContent.limit);
    }

    if (validationContent.validationType === 'min-length') {
      return Validators.minLength(+validationContent.limit);
    }

    if (validationContent.validationType === 'max') {
      return Validators.max(+validationContent.limit);
    }

    if (validationContent.validationType === 'min') {
      return Validators.min(+validationContent.limit);
    }

    if (validationContent.validationType === 'pattern') {
      return Validators.pattern(validationContent.limit.toString());
    }

    if (validationContent.validationType === 'alpha-numeric') {
      return Validators.pattern(/^[-\w\s]+$/);
    }

    if (validationContent.validationType === 'number') {
      return Validators.pattern(/^\d+$/m);
    }

    if (validationContent.validationType === 'currency') {
      return Validators.pattern(/^[0-9]*(\.[0-9]{1,2})?$/m);
    }

    return [];
  }
}
