import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoggedInUser } from '@core/models/logged-in-user.model';
import { KeycloakService } from 'keycloak-angular';

@Injectable()
export class AuthService {
  constructor(private keycloakService: KeycloakService,private router:Router) { }

  async getToken(): Promise<string> {
    const token = await this.keycloakService.getToken();
    return token;
  }
  logout() {
  
    this.keycloakService.logout().then(() => {
      this.keycloakService.clearToken()
      return;
    });
  }

  isLoggedIn(): boolean {
    let isLoggedIn: boolean;
    this.keycloakService.isLoggedIn().then((loggedIn) => {
      isLoggedIn = loggedIn;
    });
    return isLoggedIn;
  }

  async getUser(): Promise<LoggedInUser> {
    let user: LoggedInUser;
    this.keycloakService.loadUserProfile().then((usr) => {
      user = {
        username: usr.username,
        user_display_name: usr.email,
        access_token: null,
        token_type: null,
        refresh_token: null,
        user_id: +usr.id,
      };
    });
    return user;
  }

  async getUsername(): Promise<string> {
    const username =( await this.keycloakService.loadUserProfile()).username 
    return username
  }

  redirecToOtp(){
    this.router.navigate(['member-login'])
  }

  async loginfromInvitation(){
    await this.keycloakService.login({ redirectUri: window.location.origin + '/member-login' });
  }

  async loginfromReset(){
    await this.keycloakService.login({ redirectUri: window.location.origin + '/member-login' });
  }


  async backfromLoginFlow(){
    await this.keycloakService.login();
  }
}
