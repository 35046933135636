import { environment } from 'src/environments/environment';
import { AccessDetails } from './../model/access-details';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationDetails } from '../model/authentication-details';
import { LoggedInUser } from '../model/logged-in-user';

import * as authConfigData from '@assets/configs/auth-configs.json';
import * as timeFormatData from '@assets/configs/time-format.json';
import * as currencyData from '@assets/configs/currency.json';
import * as paginationData from '@assets/configs/pagination.json';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { GlobalMasterService } from './global-master.service';



@Injectable({
  providedIn: 'root'
})
export class GlobalConfigsService {

  public maxNoOfDocuments: number;
  public maxDocumentSize: number;
  public unreadMessageCount: number;
  private prntCountSubject = new BehaviorSubject<number>(0);
  private contactCountSubject = new BehaviorSubject<number>(0);
  private addresstCountSubject = new BehaviorSubject<number>(0);
  private notificationUnreadCountSubject = new BehaviorSubject<number>(0);

  constructor(private translate: TranslateService) { }

  //#region AUTH DETAILS

  public getAccessDetails(): AccessDetails {
    return authConfigData.ACCESS_DETAILS;
  }

  public getAuthenticationDetails(): AuthenticationDetails {
    return authConfigData.AUTHENTICATION_DETAILS;
  }

  public getLoggedInUserDetails(): LoggedInUser {
    return authConfigData.LOGGED_IN_USER_DETAILS;
  }
  
  public setHeaderHashValue(value:string){
    localStorage.setItem(authConfigData.ACCESS_DETAILS.headerKeyName,value);
  }

  public getHeaderHashValue():string{
    return localStorage.getItem(authConfigData.ACCESS_DETAILS.headerKeyName);
  }
  //#endregion

  //#region ENVIROMENT

  public getCurrentEnviroment(): string {
    return environment.CURRENT_MODE;
  }

  //#endregion

  //#region CURRENCY
  public getCurrencySymbol(): string {
    return currencyData.CURRENCY_SYMBOL;
  }

  public getCurrencyShortCode(): string {
    return currencyData.CURRENCY_SHORT_CODE;
  }

  public getCurrencyName(): string {
    return currencyData.CURRENCY_NAME;
  }

  //#endregion

  //#region TIME
  public getDisplayDateFormat(): string {
    return timeFormatData.DISPLAY_DATE_FORMAT;
  }

  public getDisplayTimeFormat(): string {
    return timeFormatData.DISPLAY_TIME_FORMAT;
  }

  public getDisplayDateTimeFormat(): string {
    return timeFormatData.DISPLAY_DATETIME_FORMAT;
  }

  public getDisplayDateFormatForTableCell(): string {
    return timeFormatData.DISPLAY_DATE_FORMAT_FOR_TABLE_CELL;
  }

  public getDisplayTimeFormatTableCell(): string {
    return timeFormatData.DISPLAY_TIME_FORMAT_FOR_TABLE_CELL;
  }

  public getDisplayDateTimeFormatTableCell(): string {
    return timeFormatData.DISPLAY_DATETIME_FORMAT_FOR_TABLE_CELL;
  }

  //#endregion

  //#region PAGINATION
  public getNumberOfItemsPerPage(): string {
    return paginationData.PAGE_SIZE;
  }

  public getPaginationNumberList(): Array<string> {
    return paginationData.PAGE_PER_LIST;
  }
  //#endregion

  //#region LANGUAGE
  public getcurrentLanguage(): string {
    return this.translate.currentLang;
  }
  //#endregion

  //#region Documnent
  public setMaxDocumentCount(count: number) {
    this.maxNoOfDocuments = count;
  }
  public setMaxUploadfileSize(size: number) {
    this.maxDocumentSize = size;
  }


  public getMaxDocumentCount() {
    return this.maxNoOfDocuments;
  }

  public getMaxDocumentSize() {
    return this.maxDocumentSize;
  }

  // Notifications
  public notificationUnreadCount(): Observable<number> {
    return this.notificationUnreadCountSubject.asObservable();
  }

  public sendNewUnreadMessageCount(count: number) {
    this.notificationUnreadCountSubject.next(count);
  }

  // Contact Count
  public maxContactCount(): Observable<number> {
    return this.contactCountSubject.asObservable();
  }

  public sendnewMaxContactCount(count: number) {
    this.contactCountSubject.next(count);
  }

  // Address Count
  public maxAddressCount(): Observable<number> {
    return this.addresstCountSubject.asObservable();
  }

  public sendnewMaxAddressCount(count: number) {
    this.addresstCountSubject.next(count);
  }

  // PRN Count
  public maxPrnCount(): Observable<number> {
    return this.prntCountSubject.asObservable();
  }

  public sendnewMaxPrnCount(count: number) {
    this.prntCountSubject.next(count);
  }

  //#endregion
}
