// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    development: false,
    qa: false,
    stg: false,
    staging: true,
    preProduction: false,
    production: false,

    CURRENT_MODE: 'staging',

    AUTH_SERVER_URL: '',

    /*BASE_ADMIN_URL: 'https://etf.stg.inova.lk/meta-mpp',
    BASE_META_URL: 'https://etf.stg.inova.lk/meta-mpp',
    BASE_DOC_URL: 'https://etf.stg.inova.lk/',
    BASE_DATA_URL: 'https://etf.stg.inova.lk/mppportal',
    BASE_DATA_URL2: 'https://etf.stg.inova.lk/',
    BU_DATA_URL: 'https://etf.stg.inova.lk/',*/

    BASE_ADMIN_URL: 'https://stg-api.etfb.lk/meta-mpp',
    BASE_META_URL: 'https://stg-api.etfb.lk/meta-mpp',
    BASE_DOC_URL: 'https://stg-api.etfb.lk/',
    BASE_DATA_URL: 'https://stg-api.etfb.lk/mppportal',
    BASE_DATA_URL2: 'https://stg-api.etfb.lk/',
    BU_DATA_URL: 'https://stg-api.etfb.lk',

    LGPS_URL:'https://testlgps.lankagate.gov.lk/lgps/accesslgps',
    LGPS_STRING_REQUEST_URL:'https://stg-api.etfb.lk/mppportal/v1/member-public-portal/lgps-requests',


    ACCRESS_DETAILS: {
      TOKEN: '',
      ROMOVE_TOKEN: '',
      CLIENT_DETAILS: '',
    },
    AUTHENTICATION_DETAILS: {
      ACCESS_TOKEN_NAME: 'accessToken',
      REFRESH_TOKEN: 'refreshToken',
      TOKEN_TYPE: 'tokenType',
    },
    OTP_MANAGE: {
      GENERATE: '/bu/v1/member-public-portal/member-generate-otps',
      VERIFY: '/bu//v1/member-public-portal/member-verify-otps'
    },
    COMMON_URI: {
      DOCUMENTS: {
        UPLOAD_DOCUMENT: '/v1/member-public-portal/documents',
        DOWNLOAD_DOCUMENT: '/v1/member-public-portal/documents/ref-no',
        DOCUMENT_VIEW: '/v1/member-public-portal/documents/view/ref-no'
      },
    },
    BUSSINESS_URIS: {
      EMPLOYER_RELATIONS: {
        REPORT_DOWNLOAD: '/reporting/v1/report/downloads',
      }
    }
    };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
  // import 'zone.js/dist/zone-error';  // Included with Angular CLI.
