import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppResponse } from '@core/util/model/response-models/app-response';
import { GlobalMasterService } from '@core/util/services/global-master.service';

@Injectable()
export class PermissionService {
  private userPermissions: Array<string> = new Array();
  private staticPermissions: any = new Array();
  private loaded = false;

  constructor(
    private globalMasterService: GlobalMasterService,
    private httpClient: HttpClient
  ) { }

  loadStaticPermissions(url: string): Promise<any> {
    let rt: Promise<any>;
    if (!this.loaded) {
      const promise = this.httpClient.get(url).toPromise();

      promise.then(permissions => {
        this.staticPermissions = permissions;
        this.loaded = true;
      });
      rt = promise;
    }

    return rt;
  }

  getStaticPermission(permissionCode: string): any {
    const propVal = this.staticPermissions[permissionCode];
    if (propVal) {
      return propVal;
    }
    alert('NO_PERMISSION_FOUND!. Please provide valid permission code.');
    return null;
  }

  loadUserPermissions(userName: string): Promise<any> {
    let rt: Promise<any>;
    if (!this.loaded) {
      const promise = this.globalMasterService.executeApiRequest('MstPermission_readAllMstPermissionCodeByUsername_2',
        [{ name: 'username', value: userName }], true).toPromise();

      promise.then((permissions: AppResponse<Array<string>>) => {
        this.userPermissions = permissions.data;
        this.loaded = true;
      });
      rt = promise;
    }
    return rt;
  }

  checkUserPermission(permissionCode: string): boolean {
    // return true;
    const realPermissionCode = this.getStaticPermission(permissionCode);
    return realPermissionCode ? this.userPermissions.some(permission => permission === realPermissionCode) : false;
  }

  getUserPersmissions() {
    return this.userPermissions;
  }

}
