// import { AppResponse } from '@global/first-phase-global-components/models/response-models/app-response';
import { Injectable, Injector } from '@angular/core';
import {
  HttpInterceptor,
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse, HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError, map } from 'rxjs/operators';
import { ErrorResponse } from '../models/error-response';
import { AppResponse } from '@core/util/model/response-models/app-response';
import { ServerLoadService } from './server-load.service';
import { GlobalConfigsService } from '@core/util/services/global-configs.service';
import { AuthService } from './auth.service';

@Injectable()
export class ApiInterceptorService implements HttpInterceptor {

  private activeCallCount: number = 0;

  constructor(private serverLoadService: ServerLoadService,private injector: Injector) { }

  intercept(request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const configs = this.injector.get(GlobalConfigsService);
    let clonedRequest =request.clone();
    if(configs.getHeaderHashValue()){
      clonedRequest =request.clone({ headers: request.headers.append('api-key', configs.getHeaderHashValue()) });
    }
    
    this.activeCallCount++;
    if (this.activeCallCount >= 0){
      this.serverLoadService.sendProgress(true);
    
    }



    return next.handle(clonedRequest).pipe(map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        this.activeCallCount--;
        if (this.activeCallCount === 0){
          this.serverLoadService.sendProgress(false);
        }

      }
      return event;
      // }, (err: any) => {
      //    
      //   this.activeCallCount = 0;
      //   if (err instanceof HttpErrorResponse) {
      //     if (err.status === 401) {
      //       // redirect to the login route
      //       // or show a modal
      //     } else {
      //       const tempRes: AppResponse<ErrorResponse> = {
      //         data: {
      //           detail: err.message
      //         },
      //         meta: {
      //           code: 0,
      //           message: 'Internal Error'
      //         },
      //         error: {
      //           detail: null,
      //           source: null,
      //           status: null,
      //           title: null,
      //         },
      //         paging: {
      //           page_number: 0,
      //           page_size: 0,
      //           total_records: 0
      //         }
      //       };
      //       return tempRes;
      //     }
      //   }
      // })
    }),
      catchError(err => {
        this.serverLoadService.sendProgress(false);
        const authService = this.injector.get(AuthService);
        //logout if status is 401 Unauthuorized
         if(err.status ===401){
           authService.logout()
         }

        this.activeCallCount = 0;
        const tempRes: AppResponse<ErrorResponse> = {
          meta: {
            code: 0,
            message: 'Something Went Wrong'
          },
          error: {
            detail: 'Something Went Wrong',
            source: 'Something Went Wrong',
            status: 'Something Went Wrong',
            title: 'Something Went Wrong',
          },
          paging: {
            page_number: 0,
            page_size: 0,
            total_records: 0
          }
        };

        if(err?.error?.type ==='error'){          
          return throwError(tempRes);
        }
        return throwError(err);
      })
    );

  }
}
