<!-- Issue in back button due to keycloak authentication issue -->
<!-- <button mat-icon-button class="back-button" color="primary"  (click)="backClick()" aria-label="Example icon button with a home icon">
    <mat-icon>arrow_back_ios</mat-icon>
  </button> -->
<div class="main-div">
    <mat-spinner *ngIf="isMainPageLoading"></mat-spinner>
    <mat-card class="invitaion-card" *ngIf="verifiedData && !isMainPageLoading" >
        <h5 class="login-heading">Please Enter the One-Time Password to verify your account</h5>
        <form [formGroup]="loginOTPForm" #f="ngForm" (ngSubmit)="f.form.valid && callVerifYOTP()" autocomplete="off"
            class="w-100 mt-3">
            <div class="mt-4">
                <div class="w-100 mt-2" fxLayout="column" fxLayoutGap="1rem" fxLayoutAlign="start start">
                    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="0rem" class="w-100">
                        <mat-form-field class="w-100" appearance="outline">
                            <mat-label>Enter OTP</mat-label>
                            <input type="text" id="loginOtp" name="loginOtp" matInput required
                                formControlName="loginOtp">
                            <mat-error *ngIf="memberLoginInvitationFormControls?.loginOtp?.errors">
                                • {{memberLoginInvitationFormControls?.loginOtp?.errors |
                                etfbValidation:'login_otp'}}
                            </mat-error>
                        </mat-form-field>
                        <div  fxLayout="column" fxLayoutGap="1rem" fxLayoutGap="1rem" fxLayoutAlign="center center">
                            <span class="pwd-reset">Didn't get your password? Click to Resend</span>
                            <button mat-stroked-button type="button"  *ngIf="!isResendLoading" [hidden]="isOtpWaitTimerDisplayed" [disabled]="this.sentOTPCount >=3" class="validate-button" (click)="resendOtp()"
                            color="primary">Resend OTP</button>
                            <mat-spinner [diameter]="35" *ngIf="isResendLoading"></mat-spinner> 
                            <h5 class="label-light-grey" *ngIf="isOtpWaitTimerDisplayed" matTooltip="OTP Regenerate Waiting Time">
                                {{otpGenratedInterval}}s</h5>
                        </div>
             
                    </div>
             
                    <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1rem" class="w-100 mt-2">
                        <label class="language-label" id="example-radio-group-label">Choose Your Language</label>
                        <mat-radio-group fxLayout="row" fxLayoutGap="1rem"  
                        aria-label="Select an option" [(ngModel)]="language" [ngModelOptions]="{standalone: true}">
                        <mat-radio-button color="primary" value="ENG">English</mat-radio-button>
                        <mat-radio-button color="primary" value="SIN">Sinhala</mat-radio-button>
                        <mat-radio-button color="primary" value="TAM">Tamil</mat-radio-button>
                      </mat-radio-group>
                        <button mat-raised-button type="button" class="validate-button" *ngIf="!isLoginLoading" (click)="loginClick()" color="primary">Login</button>
                        <mat-spinner [diameter]="35" *ngIf="isLoginLoading"></mat-spinner> 
                    </div>

                </div>
            </div>
        </form>
    </mat-card>
    <mat-card class="invitaion-card" *ngIf="verifiedData ===null && !isMainPageLoading">
        <h5 class="verification-error" *ngIf="!verifiedData">User Verification Failed</h5>
    </mat-card>
</div>