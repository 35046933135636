<section class="page-wrapper">
  <div fxLayout="row" class="w-100" fxLayoutAlign="end center">
    <button mat-icon-button class="close-button" aria-label="Close" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div fxLayout="column" class="w-100" fxLayoutAlign="center center">
    <div fxLayout="row" class="w-100" fxLayoutAlign="center center">
      <img src="../../../../../assets/images/icons/icons8_help_96px_1 1.png">
    </div>
    <div fxLayout="row" class="w-100 mt-3" fxLayoutAlign="center center">
      <span class="confirm-heading">{{'PLEASE_CONFIRM' | translate}}</span>
    </div>
  </div>
  <div fxLayout="column" class="w-100 mt-1" fxLayoutAlign="center" fxLayoutGap="3rem">
    <div fxLayout="row" fxLayoutAlign="center" class="w-100 mt-1">
      <span class="confirm-message" *ngIf="data != 'cancel' && !data">{{'ARE_YOU_SURE_YOU_WANT_TO_PROCEED?' | translate}}</span>
      <span class="confirm-message" *ngIf="data == 'cancel'">{{'ARE_YOU_SURE_YOU_WANT_TO_CANCEL' | translate}}</span>
      <span  class="confirm-message" *ngIf="data && data !='cancel'" class="text-center">{{data}}</span>
    </div>

    <div fxLayout.gt-sm="row" fxLayout.lt-md="column" fxLayoutAlign="center center" fxLayoutGap="10px" class="w-100 mt-1 mb-3">
      <button type="button" (click)="onSubmit()" mat-raised-button color="primary" class="confirmation-button" cdkFocusInitial>{{'YES,_DO_IT' | translate}}</button>
      <button type="button" (click)="onReset()" mat-stroked-button color="primary" class="confirmation-button">{{'GO_BACK' | translate}}</button>
    </div>


    <!-- <div *ngIf="!data" fxLayout="row" fxLayoutAlign="center" class="w-100 mt-4">
          <h4>Are You Sure, you want to proceed ?</h4>
        </div> -->
    <!-- <div fxLayout="row" fxLayoutAlign="center" class="w-100">

          <div *ngIf="!data" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px" class="w-100 mt-2 mb-3">
            <button type="button" (click)="onSubmit()" mat-raised-button class="btn-search"><i
                class="fas fa-check-circle mr-2 pb-1"></i>Confirm</button>
            <button type="button" (click)="onReset()" mat-raised-button color="warn"><i class="fas fa-ban mr-2 pb-1"></i>Decline</button>
          </div>
        </div> -->
  </div>
</section>
