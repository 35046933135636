import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { PermissionService } from '@core/services/permission.service';
import { KeycloakService, KeycloakAuthGuard } from 'keycloak-angular';
import { UiService } from './ui.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard extends KeycloakAuthGuard {
  constructor(
    protected router: Router,
    private uiService: UiService,
    protected keycloakAngular: KeycloakService,
    private permissionService: PermissionService
  ) {
    super(router, keycloakAngular);
  }

  public async isAccessAllowed(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    AuthGuard
    if (!this.authenticated) {
      await this.keycloakAngular.login({ redirectUri: window.location.origin + '/member-login' });
    }

    if (route.data?.permission && !this.permissionService.checkUserPermission(route.data.permission)) {
      this.uiService.showErrorToastr('Unauthorized access', 'Unauthorized action detected');
      this.router.navigate(['/etfb-member-portal']);
    }

    return true;
  }
}
