import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { MemberPortalUserInvitationResponse, MemberPortalLoginUserResponse, OtpGeneratedResponse } from '@core/models/universal';
import { MemberPortalUserInvitationVerifyRequest } from '@core/models/universal/global-login-create-user/member-portal-user-invitation-verify-request.model';
import { AuthService } from '@core/services/auth.service';
import { MstDocumentService } from '@core/services/mst-document.service';
import { ConfirmationMessageService } from '@core/services/confirmation-message.service';
import { UiService } from '@core/services/ui.service';
import { Parameter } from '@core/util/model/api-service/parameter.model';
import { AppResponse } from '@core/util/model/response-models/app-response';
import { GlobalMasterService } from '@core/util/services/global-master.service';
import { ValidationService } from '@core/util/services/validation.service';
import {Location} from '@angular/common';
import * as otpJsonData from '../../../../../assets/otp/otp.json';
// main Sections of the ui
type UiSections ="INVITATION" | 'OTP' | 'RESETUSER' | 'FORGOTUSERNAME'

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  matcher = new conirmPasswordErrorStateMatcher();
  public userNameForm:FormGroup;
  public loginOTPForm:FormGroup;
  public createUserForm:FormGroup;
  public forgotUserNameForm:FormGroup;
  public isLoginInvitationLoading:boolean;
  public usernameLoading =false;
  public invitationVerified =false;
  public language ='ENG'
  public loginLoading =false;
  public resendLoading =false;
  public otpValidationLoading = false;
  public otpVerified =false;
  public invitationVerifiedData:MemberPortalUserInvitationResponse;
  public passwordHide =true;
  public otpGenratedInterval: number;
  public isOtpGenerated: boolean;
  // First active section is INVITATION
  public activeScreen: UiSections ="INVITATION"

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private uiService: UiService,
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private mstDocumentService: MstDocumentService,
    private authService:AuthService,
    private sanitizer: DomSanitizer,
    private validationService: ValidationService,
    private confirmationMessageService: ConfirmationMessageService,
    private globalMasterService: GlobalMasterService,
    private activatedRoute: ActivatedRoute,
    public dialog: MatDialog,
    private _location: Location
  ) { }

  ngOnInit(): void {
    // this.initLoginForm();
    
    this.inituserNameForm();
    this.initCreateUserForm()
    this.initForgotUserNameForm();
  }

  get usernameFormControls(){
    return this.userNameForm.controls
  }

  get createUserControls(){
    return this.createUserForm.controls
  }

  get otpFormControls(){
    return this.loginOTPForm.controls
  }

  onValidateInvitationSubmit(){
    this.validateusername()
  }

  private inituserNameForm(){
    this.userNameForm = this.formBuilder.group({
      username: [null, { validators: this.validationService.getValidations('login_invitation') }],
    })
  }

  public backClick(){
    this._location.back();
 }



  private initCreateUserForm(){
    this.createUserForm = this.formBuilder.group({
      password: [null, { validators: this.validationService.getValidations('password') }],
      confirmPassword:[null ,{validators: this.validationService.getValidations('password') }],
      resetOTP: [null, { validators: this.validationService.getValidations('login_otp') }],
    }, { validators: this.checkPasswords })
  }

  private initForgotUserNameForm(){
    this.forgotUserNameForm = this.formBuilder.group({
      personRefNo: [null, { validators: [Validators.pattern('^[0-9]*$'), Validators.maxLength(12),Validators.required] }]});
  }

 




  private validateusername(){
    this.otpGenratedInterval = otpJsonData.LOGIN_OTP_INTERVAL;
    const parameterList = new Array<Parameter>();

    const request:any ={
      username:this.userNameForm.value.username
    }
    this.usernameLoading =true;
    parameterList.push({name:'request',value:request})
    this.globalMasterService.executeApiRequest<AppResponse<MemberPortalLoginUserResponse>>(
     'MemberPortalUserInvitation_restPwOtp_1',
     parameterList)
     .subscribe((res: AppResponse<MemberPortalLoginUserResponse>) => {
       if (!res.error) {
        this.invitationVerified = true;
        this.invitationVerifiedData =res.data
        // Change Active Screen
        this.activeScreen ='RESETUSER'
        this.usernameLoading =false;
        this.isOtpGenerated = true;
        const interval = setInterval(() => {
          if (this.otpGenratedInterval === 0) {
            clearInterval(interval);
          }
          this.otpGenratedInterval = this.otpGenratedInterval - 1;
          if (this.otpGenratedInterval <= 0) {
            this.isOtpGenerated = false;
          }
        }, 1000);
        //  this.selectedAddress =res?.data
        //   this.patchValues(this.selectedAddress);
        this.uiService.showSuccessToastr('Success', 'Username Validated Successfully');
       } else {
         this.usernameLoading =false;
         this.uiService.showErrorToastr('Error', res.error.detail);
       }
     }),(error =>{
      this.uiService.showErrorToastr('Error', 'Something Went Wrong');
     });
  }


  onForgotUserNameSubmit(){
    this.forgotUserNameForm.markAllAsTouched();



    if(this.forgotUserNameForm.invalid){
      return
    }

    const parameterList = new Array<Parameter>();

    parameterList.push({name:'ref_no',value:this.forgotUserNameForm.get('personRefNo').value},)
    this.usernameLoading =true;
    this.globalMasterService.executeApiRequest<AppResponse<MemberPortalLoginUserResponse>>(
     'MemberPortalUserInvitation_getUserName_1',
     parameterList)
     .subscribe((res: AppResponse<MemberPortalLoginUserResponse>) => {
       if (!res.error) {
         this.usernameLoading =false;
         this.forgotUserNameForm.reset();
         this.activeScreen ='INVITATION';
        this.uiService.showSuccessToastr('Success', 'Successfully Sent');
       } else {
        this.usernameLoading =false;
         this.uiService.showErrorToastr('Error', 'Username Sending failed');
       }
     });
  }
  

  public loginClick(){
 
    this.createUserForm.markAllAsTouched()
    if(this.createUserForm.invalid){
      return
    }

    const parameterList = new Array<Parameter>();

 
     const request ={
      confirm_password: this.createUserForm.value.confirmPassword,
      password: this.createUserForm.value.password,
      username: this.userNameForm.value.username,
      otp_value: this.createUserForm.value.resetOTP
    }

    parameterList.push({name:'request',value:request})
    this.loginLoading =true;
    this.globalMasterService.executeApiRequest<AppResponse<MemberPortalLoginUserResponse>>(
     'MemberPortalUserInvitation_changePassword_1',
     parameterList)
     .subscribe((res: AppResponse<MemberPortalLoginUserResponse>) => {
       if (!res.error) {
         this.loginLoading =false;
         this.authService.loginfromReset()
        //  this.selectedAddress =res?.data
        //   this.patchValues(this.selectedAddress);
        this.uiService.showSuccessToastr('Success', 'Password Reset Success');
       } else {
        this.loginLoading =false;
         this.uiService.showErrorToastr('Error', res.error.detail);
       }
     });
  }

  checkPasswords(group: FormGroup) { // here we have the 'passwords' group
    const password = group.get('password').value;
    const confirmPassword = group.get('confirmPassword').value;
    if(group.get('confirmPassword').dirty){
      return password?.trim() === confirmPassword?.trim() ? null : { notSame: true }   
    }
    else{
      return null
    }
     
  }
  
}


export class conirmPasswordErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    // return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    const invalidParent = !!(
      control
      && control.parent
      && control.parent.invalid
      && (control.dirty || control.touched ||  control.parent.hasError('notSame') || isSubmitted))
       return (invalidParent);
  }
}