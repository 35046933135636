import { ValidationContent } from './../model/validation/validation.model';
import { Pipe, PipeTransform } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import * as validationConfigs from 'src/assets/configs/validation-configs.json';
import { Validation } from '../model/validation/validation.model';

@Pipe({
  name: 'etfbValidation'
})
export class EtfbValidationPipe implements PipeTransform {

  transform(value: ValidationErrors, code: string): string {
    const fieldValidationList: Validation = (validationConfigs.VALIDATIONCONFIGS as Array<Validation>).find(url => url.code === code);

    if (value.required) {
      const validation = fieldValidationList.validationList.find(v => v.validationType === 'required');

      return this.getErrorMessage(validation);
    }

    if (value.maxlength) {
      const validation = fieldValidationList.validationList.find(v => v.validationType === 'max-length');

      return this.getErrorMessage(validation);
    }

    if (value.minlength) {
      const validation = fieldValidationList.validationList.find(v => v.validationType === 'min-length');

      return this.getErrorMessage(validation);
    }

    if (value.email) {
      const validation = fieldValidationList.validationList.find(v => v.validationType === 'email');

      return this.getErrorMessage(validation);
    }

    if (value.min) {
      const validation = fieldValidationList.validationList.find(v => v.validationType === 'min');

      return this.getErrorMessage(validation);
    }

    if (value.max) {
      const validation = fieldValidationList.validationList.find(v => v.validationType === 'max');

      return this.getErrorMessage(validation);
    }

    if (value.number) {
      const validation = fieldValidationList.validationList.find(v => v.validationType === 'number');

      return this.getErrorMessage(validation);
    }

    if (value.pattern) {
      const numberValidation = fieldValidationList.validationList.find(v => v.validationType === 'number');
      if (numberValidation) {
        return numberValidation.errorMessage;
      }

      const alphaNumericValidation = fieldValidationList.validationList.find(v => v.validationType === 'alpha-numeric');
      if (alphaNumericValidation) {
        return alphaNumericValidation.errorMessage;
      }

      const currencyValidation = fieldValidationList.validationList.find(v => v.validationType === 'currency');
      if (currencyValidation) {
        return currencyValidation.errorMessage;
      }

      const validation = fieldValidationList.validationList.find(v => v.validationType === 'pattern');
      return this.getErrorMessage(validation);
    }
  }

  private getErrorMessage(validation: ValidationContent): string {
    return validation?.errorMessage ?? 'The value is not allowed.';
  }
}
